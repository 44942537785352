import Swal from "sweetalert2";

const MainSweet = Swal.mixin({
    toast: true,
    icon: '',
    title: '',
    iconColor: 'white',
    customClass: {
        popup: 'colored-toast',
    },
    animation: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

class sweet {
  
    confirmation_delete(deletName,func){
        MainSweet.fire({
            title: 'estas seguro de eliminar '+deletName+' ?',
            icon:'question',
            toast: false,
            position: 'center',
            showCancelButton:true,
            showConfirmButton: true,
            timer: false,
            confirmButtonText: '  eliminar ',
            cancelButtonText: '  Cancelar ',
           
        }).then( (result) =>{
            if(result.isConfirmed){
                func();
            }
        } );
    }
    
    
    ToastMsg(msg,icon,title){
        MainSweet.fire({
            title: title,
            icon:icon,
            text: msg
        });
    }


    raw(){
        return Swal;
    }

}
  
  export default new sweet();